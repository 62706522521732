import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgSelectComponent, DropdownPosition} from '../../ng-select';

@Component({
  selector: 'shared-bm-ng-select',
  templateUrl: './bm-ng-select.component.html',
  styleUrls: ['./bm-ng-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BmNgSelectComponent),
    multi: true
  }]
})
export class BmNgSelectComponent implements ControlValueAccessor {
  @Input() idsMode: boolean = false;
  @Input() checkSelectedInItems: boolean = false;
  @Input() sortable: boolean = true;
  @Input() bindValue!: string;
  @Input() bindLabel: string = 'name';
  @Input() groupBy: string;
  @Input() set items(values: any) {
    this._items = values;
    this.sortAlphabetically();
  }
  get items(): any[] {
    return this._items;
  }
  private _items: any[] = [];

  @Input() selected: any = [];
  @Input() multiple: boolean = true;
  @Input() searchable: boolean = true;
  @Input() clearable: boolean = true;
  @Input() tagging: boolean = false;
  @Input() disabled: boolean;
  @Input() virtualScroll: boolean = true;
  @Input() placeholder: string;
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() appendTo: string;
  @Input() searchFn: (term: string, item: any) => boolean = null;
  @Input() ngLabelTmp: TemplateRef<HTMLElement>;
  @Input() ngMultiLabelTmp: TemplateRef<HTMLElement>;
  @Input() ngOptgroupTmp: TemplateRef<HTMLElement>;
  @Input() ngOptionTmp: TemplateRef<HTMLElement>;

  @Output() changeSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NgSelectComponent, { static: true }) ngSelectComponent: NgSelectComponent;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  compareFunction(item: any, selected: any): boolean {
    const itemIdentifier = item[this.bindValue || 'id'] || item;
    const itemLabel = item[this.bindLabel] || item;
    const selectedIdentifier = selected[this.bindValue || 'id'] || selected;
    return itemIdentifier === selectedIdentifier || itemLabel === selectedIdentifier;
  }

  onSelectedChange(selected: any): void {
    if (this.ngSelectComponent.isOpen) {
      (<any>this.ngSelectComponent)._changeSearch(null);
      this.ngSelectComponent.itemsList.resetFilteredItems();
    }
    this.ngSelectComponent.blur();
    this.onChange(this.idsMode ? this.selected: selected);
    this.changeSelected.emit(this.idsMode ? this.selected: selected);
  }

  private sortAlphabetically() : void {
    if (this.sortable) {
      this.items?.sort((a, b) => {
        const aName = String( a[this.bindLabel] ?? a);
        const bName = String(b[this.bindLabel] ?? b);
        return aName.localeCompare(bName);
      });
    }
  }

  onChange(selected) {}

  onTouched() {}

  writeValue(selected: any): void {
    this.selected = selected;
    this.cdr.detectChanges();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
