import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';
import {ResponseHandlerService} from '../response-handler/response-handler.service';
import {GoNativeService} from '@shared/lib/go-native/go-native.service';

@Injectable({ providedIn: 'root' })
export class FileOpenerService {
  constructor(
    private responseHandlerService: ResponseHandlerService,
    private goNativeService: GoNativeService
  ) {
  }

  downloadFile(data: string | Blob, fileName?: string, _mimeType?: string, isImage?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.goNativeService.isAndroid()) {
        if (isImage) {
          this.goNativeService.downloadImage(<string>data);
        } else {
          this.goNativeService.downloadFile(<string>data);
        }
      } else {
        if (typeof data === 'string') {
          fetch(data)
            .then(async res => {
              if (res.ok) {
                const blob = await res.blob();
                const serverFilename = this.getServerFilenameFromHeader(res.headers.get('Content-Disposition'))
                return { blob, serverFilename };
              }
              throw new Error('File processing error');
            })
            .then(({ blob, serverFilename }) => {
              saveAs(blob, serverFilename || fileName);
            })
            .catch(() => {
              this.responseHandlerService.emitErrorMessage('File processing error');
              reject();
            });
        } else {
          saveAs(data, fileName);
        }
      }
      resolve();
    });
  }

  private getServerFilenameFromHeader(header: string): string {
    // Content-Disposition header should be in format 'attachment; filename="${fileName}.${fileExtension}"'
    try {
      return header && header
        .split(';')
        .find((n: string) => n.includes('filename='))
        .replace('filename=', '')
        .replace(/['"]/g, '')
        .trim();
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  openFile(url: string, fileName: string, contentType: string, isImage: boolean): void {
    if (isImage) {
      window.open(url, '_blank');
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    }
  }

}
